<template>
  <div class="item">
    <TitleHeader msg="设置支付密码"></TitleHeader>

    <el-row class="Repass">
      <el-col class="wraper">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <span class="wraperTit" ></span><span></span>
        
          <el-form-item class="newPass" label="支付密码：" prop="newPassword">
            <el-input
              placeholder="请输入新密码"
              v-model="ruleForm.newPassword"
              type="password"
              autocomplete="off"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              :maxlength="6"
            >
            </el-input>
          </el-form-item>
          <el-form-item  class="passMsg">请设置您的密码（密码由6位数字组成)</el-form-item>
          <el-form-item label="再次确认：" prop="checkPassword">
            <el-input
              placeholder="请再次输入新密码"
              v-model="ruleForm.checkPassword"
              type="password"
               autocomplete="off"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              :maxlength="6"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button     :class="!ruleForm.checkPassword || !ruleForm.newPassword  ? 'SureBtn disabled' : 'SureBtn'"   @click="submitForm('ruleForm')"
              >确定</el-button
            >
             <el-button class="resetBtn" @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  .Repass {
    padding: 30px;
    .num {
      font-weight: 600;
      color: #f04134;
      padding-right: 30px;
    }
    .wraper {
      display: flex;
      justify-content: left;
      align-items: center;
      .newPass{
        margin-bottom: 0px;
      }
      .passMsg{
        font-size: 12px;
        color: #999;
      }
      .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:52%;
      }
      .SureBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        width: 136px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
      }
      .SureBtn.disabled {
          background-color: #ffcccc;
          border-color: #ffcccc;
          color: #fff;
          cursor: not-allowed; // 鼠标变化
        }
      .resetBtn{
        width:66px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
      }
    }
    /deep/.el-form-item__error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 10px;
      left: calc(52% + 10px);
    }
  }
}
</style>
<script>
import {setPassword} from "api/member.js"
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "Paypass",
  data() {
    //表单验证
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if(value.length<6){
           callback(new Error("支付密码为六位数"));
        }else{
            if (this.ruleForm.checkPassword !== "") {
              this.$refs.ruleForm.validateField("checkPassword");
            }
            callback();
        }
        
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      canClick: true, //添加canClick
      ruleForm: {
        newPassword: "",
        checkPassword: "",
      },
      rules: {
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    //   点击提交 验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let  Updatedata={
            password: this.$md5(this.ruleForm.newPassword)
          }

         
          //设置支付密码
          setPassword(Updatedata).then((data)=>{
            if(data.data.code===400){
               this.$message.error(data.data.msg);
               return
            }else{
              this.$message({
                message:data.data.msg,
                type: 'success'
              });
              if(this.$route.query.typeUp==undefined){
                  //直接跳转进来设置支付密码
                  // alert("直接跳转进来设置支付密码")
                  this.$router.push({ name: "Home" });
              }else if(this.$route.query.typeUp=="Order"){
                    // alert("从订单支付跳转进来")
                    this.$router.push({ name: "OrderSumbit" });
              }
              else if(this.$route.query.typeUp=="TransferAccounts"){
                      // alert("从转账跳转进来")
                  this.$router.push({ name: "TransferAccount" });
              }
              
            }
          })

        } else {
          return false;
        }
      });
    },

    //重置
     resetForm(formName) {
        this.$refs[formName].resetFields();
      },

  },
  created() {
       
  },
  components: {
    TitleHeader,
  },
};
</script>
